/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from 'components/Link';

const block = 'mini-tease-local';

/**
 * @param {object} arg0
 * @param {boolean} arg0.isFlagged
 * @param {string} arg0.url
 * @param {string} arg0.icid
 */
function WatchNowTease({
  isFlagged = true,
  url = 'https://www.nbcnews.com/now',
  icid = 'now_hp_header',
}) {
  return (
    <div className={classNames('watch-now', block, { [`${block}--live`]: isFlagged })}>
      <Link className={`${block}__link`} href={url} icid={icid}>
        Watch
      </Link>
    </div>
  );
}

WatchNowTease.propTypes = {
  isFlagged: PropTypes.bool,
  url: PropTypes.string,
  icid: PropTypes.string,
};


export { WatchNowTease };
