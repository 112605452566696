import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import Breakpoints from 'lib/Breakpoints';
import { schedule as schedulePropType } from 'lib/CustomPropTypes/TVE';
import { stripQueryParams } from 'lib/urlUtils';

import ScheduleProvider from 'components/TVE/ScheduleProvider';
import StandaloneSignIn from 'components/TVE/StandaloneSignIn';
import TypeIcon from 'components/TypeIcon';
import LoadingText from 'components/packages/Waffle/LoadingPlaceholders/LoadingText';

const block = 'mini-player';

/**
 * Implements the MSNBC TVE page and content.
 * @param {object} props props object
 * @param {object} props.epgCurrent current schedule
 * @param {boolean} props.epgLoading if schedule is loading
 * @param {string} props.headline headline text
 * @param {string} props.pageView the view of the page
 * @param {string} props.path path of the current page
 * @param {string} props.url url to redirect to
 */
function MsnbcTVE({
  epgCurrent = {},
  epgLoading = false,
  headline,
  pageView,
  path,
  url,
}) {
  /**
   * Get the tease video
   */
  const getTease = () => {
    // Default to current schedule
    if (epgCurrent?.thumbnail) {
      return (
        <video width="142px" height="80px" autoPlay loop muted playsInline>
          <source src={epgCurrent.thumbnail} type="video/mp4" />
        </video>
      );
    }

    return null;
  };

  /**
   * Get the headline text
   */
  const getHeadline = () => {
    // Manual override
    if (headline) {
      return headline;
    }

    if (epgLoading) {
      return <LoadingText />;
    }

    // Default to current schedule
    const current = get(epgCurrent, 'program.title', null);
    return (
      <>
        Watch Live:
        &nbsp;
        {current}
      </>
    );
  };

  // Special treatment mobile/cover
  const mobileCover = Breakpoints.isS() && pageView === 'front' && stripQueryParams(path) === '/';

  return (
    <div className={`${block} ${mobileCover ? `${block}--light` : ''}`} data-testid="MsnbcTVE-page">
      <button
        className={`${block}__button`}
        onClick={() => {
          window.location = url;
        }}
        type="button"
      >
        <div className={`${block}__tease`}>
          {getTease()}
          <TypeIcon className={`${block}__typeicon`} type="video" />
        </div>
        <div className={`${block}__content`}>
          <span className={`${block}__logo icon icon-msnbc-tve`} />
          <h1 className={`${block}__headline`}>
            {getHeadline()}
          </h1>
        </div>
      </button>

      {!mobileCover && (
        <div className={`${block}__sign-in`}>
          <StandaloneSignIn
            className={`${block}__sign-in-button`}
            redirectUrl={url}
          >
            <span className="icon icon-lock" />
            <span className={`${block}__sign-in-button-text`}>
              Sign In
            </span>
          </StandaloneSignIn>
        </div>
      )}
    </div>
  );
}


MsnbcTVE.propTypes = {
  epgCurrent: PropTypes.shape(schedulePropType),
  epgLoading: PropTypes.bool,
  headline: PropTypes.string,
  pageView: PropTypes.string,
  path: PropTypes.string,
  url: PropTypes.string.isRequired,
};

export default ScheduleProvider(MsnbcTVE);
