/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React from 'react';

const LogoTodayAllDay = ({
  showIcon = true,
  block = 'mini-player',
}) => (
  <span className={`${block}__logo--today-all-day`} data-test="mini-player__logo--today-all-day">
    {showIcon && (
      <svg className={`${block}__logo ${block}__logo-icon`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 20" fill="currentColor">
        <path fillRule="evenodd" d="M5.167 18.82c-.023.329-.034.66-.034.995H0c0-.345.009-.687.026-1.027C.56 8.322 9.208 0 19.797 0c10.662 0 19.355 8.435 19.78 19.002.011.27.017.54.017.813h-5.132c0-.304-.01-.606-.029-.906-.468-7.673-6.855-13.772-14.636-13.772-7.752 0-14.118 6.052-14.63 13.683zm6.619.06a8.064 8.064 0 00-.054.935H6.599c0-.293.01-.583.029-.87.448-6.89 6.172-12.34 13.169-12.34 7.008 0 12.74 5.466 13.172 12.37.017.278.025.558.025.84h-5.131c0-.295-.016-.585-.048-.873-.435-4.042-3.865-7.2-8.018-7.2-4.131 0-7.547 3.126-8.011 7.138zm8.011-5.67a6.6 6.6 0 016.599 6.605H13.198a6.601 6.601 0 016.599-6.605z" />
      </svg>
    )}
    <svg className={`${block}__logo ${block}__logo-text`} viewBox="0 0 104 13" xmlns="http://www.w3.org/2000/svg">
      <g className={`${block}__logo-today`}>
        <path d="M7.40514 3.04656V11.5597H4.33954V3.04656H0.421387V0.614218H11.3777V3.04656H7.40514Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M18.0081 11.7683C21.3277 11.7683 23.9398 9.45753 23.9398 6.08701C23.9398 2.71648 21.3277 0.405762 18.0081 0.405762C14.6886 0.405762 12.0765 2.71648 12.0765 6.08701C12.0765 9.45753 14.6886 11.7683 18.0081 11.7683ZM18.0084 3.01208C19.6953 3.01208 20.7656 4.22825 20.7656 6.08725C20.7656 7.94625 19.6953 9.16242 18.0084 9.16242C16.3214 9.16242 15.2511 7.94625 15.2511 6.08725C15.2511 4.22825 16.3214 3.01208 18.0084 3.01208Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M25.6003 0.614218V11.5597H30.1171C33.9082 11.5597 36.2482 9.50962 36.2482 6.08698C36.2482 2.66433 33.9082 0.614218 30.1171 0.614218H25.6003ZM29.8994 9.23207H28.6841V2.94273H29.8994C31.8948 2.94273 33.0738 4.08941 33.0738 6.07003C33.0738 8.08539 31.8948 9.23207 29.8994 9.23207Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M44.4932 0.614218H41.0466L36.4936 11.5597H39.5955L40.448 9.44013H44.9104L45.7629 11.5597H49.0462L44.4932 0.614218ZM41.3367 7.23419L42.6428 4.00266H42.7153L44.0214 7.23419H41.3367Z" fill="currentColor" />
        <path d="M55.8699 0.614218L53.421 4.55808L50.8452 0.614218H47.4168L51.8066 7.40739V11.5597H54.7996V7.40739L59.1894 0.614218H55.8699Z" fill="currentColor" />
      </g>
      <g className={`${block}__logo-allday`}>
        <path d="M72.4412 10.0101C71.7573 10.6849 70.7173 11.5465 69.6061 11.5465C68.8795 11.5465 68.6373 11.0152 68.6373 10.5701C68.6373 10.2421 68.7143 9.84752 68.791 9.45466C68.8016 9.40041 68.8122 9.34619 68.8225 9.29219L70.1617 2.42889C70.3042 1.69661 70.2899 1.56739 69.9053 1.38073L69.4066 1.16535L69.4636 0.806392L72.8401 0.504867L72.9825 0.634092C72.9825 0.634092 72.7546 1.36637 72.5694 2.31402L71.1732 9.43577L71.1666 9.46887C71.1405 9.59953 71.1162 9.7208 71.1162 9.86652C71.1162 10.168 71.3584 10.1824 71.8286 9.88088L72.1705 9.67986L72.4412 10.0101Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M64.3483 9.98139C63.693 10.8142 62.8524 11.5608 61.8125 11.5608C60.687 11.5608 60.0744 10.7567 60.0744 9.45013C60.0744 6.3918 62.4535 3.69242 64.8185 3.69242C65.716 3.69242 66.2716 3.92216 66.813 4.30983H66.87L67.8245 3.66371L68.0809 3.77857C68.0809 3.77857 67.796 4.49649 67.4826 6.01848L66.7988 9.43577C66.794 9.45996 66.7892 9.48333 66.7845 9.50602L66.7842 9.50735C66.7613 9.61878 66.7418 9.71397 66.7418 9.80909C66.7418 10.1537 66.9412 10.1968 67.3971 9.9096L67.7248 9.70858L68.0097 10.0388C67.3971 10.6562 66.2716 11.5465 65.2174 11.5465C64.4196 11.5465 64.3341 10.9578 64.3341 10.5988C64.3341 10.4265 64.3626 10.1537 64.4053 9.98139H64.3483ZM62.4963 9.26347C62.4963 9.89524 62.6672 10.1537 63.0519 10.1537C63.5363 10.1537 64.0777 9.63679 64.4766 9.11989L65.3741 4.81238C65.2744 4.52521 65.1177 4.29548 64.7188 4.29548C63.6787 4.29548 62.4963 6.86562 62.4963 9.26347Z" fill="currentColor" />
        <path d="M73.9608 11.5465C75.072 11.5465 76.112 10.6849 76.7958 10.0101L76.5251 9.67986L76.1832 9.88088C75.7131 10.1824 75.4709 10.168 75.4709 9.86652C75.4709 9.72086 75.4951 9.59962 75.5212 9.46901L75.5279 9.43577L76.9241 2.31402C77.1093 1.36637 77.3372 0.634092 77.3372 0.634092L77.1947 0.504867L73.8183 0.806392L73.7613 1.16535L74.2599 1.38073C74.6446 1.56739 74.6589 1.69661 74.5164 2.42889L73.1772 9.29219C73.1668 9.34616 73.1563 9.40035 73.1457 9.45457C73.069 9.84747 72.992 10.242 72.992 10.5701C72.992 11.0152 73.2342 11.5465 73.9608 11.5465Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M84.525 9.95267C83.8697 10.7855 82.9579 11.5608 81.8894 11.5608C80.764 11.5608 80.1228 10.7567 80.1228 9.3927C80.1228 6.36308 82.4878 3.69242 84.867 3.69242C85.3229 3.69242 85.5935 3.79293 85.736 3.86472H85.793C85.793 3.86472 85.8642 3.3909 85.9639 2.85964L86.0494 2.42889C86.2061 1.66789 86.1776 1.56739 85.793 1.38073L85.3229 1.16535L85.3798 0.806392L88.7563 0.504867L88.8987 0.634092C88.8987 0.634092 88.6565 1.38073 88.4713 2.31402L87.0324 9.43577C87.0276 9.46039 87.0227 9.48415 87.0179 9.50723C86.995 9.61874 86.9755 9.71391 86.9755 9.80909C86.9755 10.1537 87.1749 10.1968 87.6308 9.9096L87.9585 9.70858L88.2434 10.0388C87.6308 10.6562 86.4911 11.5465 85.4226 11.5465C84.6248 11.5465 84.5108 10.9578 84.5108 10.5988C84.5108 10.4265 84.5535 10.125 84.582 9.95267H84.525ZM82.5163 9.24911C82.5163 9.95267 82.7442 10.1968 83.1289 10.1968C83.6133 10.1968 84.2544 9.62243 84.6533 9.10553L85.565 4.82674C85.4796 4.53957 85.2374 4.29548 84.8242 4.29548C83.713 4.29548 82.5163 6.87998 82.5163 9.24911Z" fill="currentColor" />
        <path fillRule="evenodd" clipRule="evenodd" d="M90.2917 11.5608C91.3317 11.5608 92.1722 10.8142 92.8276 9.98139H92.8846C92.8418 10.1537 92.8133 10.4265 92.8133 10.5988C92.8133 10.9578 92.8988 11.5465 93.6966 11.5465C94.7509 11.5465 95.8763 10.6562 96.4889 10.0388L96.204 9.70858L95.8763 9.9096C95.4204 10.1968 95.221 10.1537 95.221 9.80909C95.221 9.71391 95.2406 9.61874 95.2635 9.50723L95.2639 9.50524C95.2685 9.48279 95.2732 9.45968 95.278 9.43577L95.9618 6.01848C96.2752 4.49649 96.5602 3.77857 96.5602 3.77857L96.3037 3.66371L95.3492 4.30983H95.2922C94.7509 3.92216 94.1952 3.69242 93.2977 3.69242C90.9328 3.69242 88.5536 6.3918 88.5536 9.45013C88.5536 10.7567 89.1662 11.5608 90.2917 11.5608ZM91.5311 10.1537C91.1465 10.1537 90.9755 9.89524 90.9755 9.26347C90.9755 6.86562 92.158 4.29548 93.198 4.29548C93.5969 4.29548 93.7536 4.52521 93.8533 4.81238L92.9558 9.11989C92.5569 9.63679 92.0155 10.1537 91.5311 10.1537Z" fill="currentColor" />
        <path d="M100.908 10.8142C99.3414 12.9392 98.0022 14.3894 96.5918 14.3894C95.794 14.3894 95.3096 13.973 95.3096 13.2982C95.3096 12.7813 95.68 12.451 96.1501 12.451C96.559 12.451 96.7757 12.6826 96.9744 12.8949C97.1427 13.0747 97.2981 13.2407 97.5463 13.2407C97.9025 13.2407 98.3014 12.9536 98.9425 12.25C98.686 9.26347 98.3584 6.93742 98.0877 5.86054C97.9025 5.14262 97.7458 5.14262 97.1474 5.57337L96.9622 5.7026L96.6773 5.34364C97.4181 4.52521 98.5293 3.69242 99.3271 3.69242C99.8542 3.69242 100.139 3.97959 100.31 4.82674C100.595 6.19078 100.809 7.91378 100.908 9.66551H100.965C101.863 8.34454 102.718 6.76511 102.718 5.77439C102.718 5.54318 102.58 5.37638 102.436 5.20078C102.275 5.00562 102.105 4.79957 102.105 4.48213C102.105 3.96523 102.561 3.69242 103.017 3.69242C103.53 3.69242 104 4.03702 104 4.78366C104 6.09027 102.561 8.57427 100.908 10.8142Z" fill="currentColor" />
      </g>
    </svg>
  </span>
);

LogoTodayAllDay.propTypes = {
  showIcon: PropTypes.bool,
  block: PropTypes.string,
};


export default LogoTodayAllDay;
