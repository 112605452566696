import React from 'react';
import PropTypes from 'prop-types';

import { block as parentBlock } from './helper';

const block = `${parentBlock}__back-button`;

function ProviderSelectBackButton({
  onClick = Function.prototype,
  text = null,
}) {
  return (
    <div className={block}>
      <button
        type="button"
        onClick={onClick}
        className={`${parentBlock}__button ${block}__button`}
        data-testid={`${block}__button`}
      >
        <span className="icon icon-back" />
        <span className="dn-m">Back</span>
        <span className="dn db-m">Go Back</span>
      </button>

      {text && (
        <h3 className={`${parentBlock}__heading-h3 ${block}__text`}>
          {text}
        </h3>
      )}
    </div>
  );
}

ProviderSelectBackButton.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
};


export default ProviderSelectBackButton;
