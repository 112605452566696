import React, {
  useState, useEffect, useRef, useContext,
} from 'react';
import { stub as $t } from '@nbcnews/analytics-framework';

import { IconClose } from 'components/Icon/Close';
import Link from 'components/Link';
import { Save } from 'components/SocialShareMenu/Save';

import { useDispatch, useSelector } from 'react-redux';
import { loadMostPopularCuratedList, loadMostPopularRecipes } from 'redux/modules/navbar';

import { FeatureFlagContext } from 'lib/ContextTypes';

import styles from './styles.module.scss';

$t('register', 'trending_nav');

const drawnPath = 'M22.663 17.743c0-.585-.096-.71-.688-1.034-.012-.007-.419-.224-.552-.299a6.674 6.674 0 0 1-.677-.428c-1.816-1.322-2.718-2.686-2.718-7.315-.289-2.406-1.826-5.037-4.5-5.905l-.829-.27.149-.813c.02-.112.029-.183.029-.24 0-.794-.69-1.439-1.545-1.439-.856 0-1.546.645-1.546 1.438 0 .058.009.129.03.24l.097.93-.777.154c-2.674.868-4.44 3.499-4.5 5.905 0 4.629-.903 5.993-2.719 7.315-.21.153-.426.288-.676.428-.134.075-.54.292-.553.3-.592.322-.688.448-.688 1.033 0 .714 3.255 1.887 7.882 2.263l1.136.093-.212 1.055a2.25 2.25 0 0 0-.05.444c0 1.325 1.152 2.402 2.576 2.402 1.424 0 2.575-1.077 2.575-2.402a2.25 2.25 0 0 0-.05-.444l-.211-1.055 1.135-.093c4.628-.376 7.882-1.549 7.882-2.263Z';
/**
 * Trending Nav
 * This component will display a list of popular stories, products, and recipes
 * from TODAY.
 * @returns {React.Element} The Trending Nav component
 */
function TrendingNav() {
  const [menuOpen, setMenuOpen] = useState(false);
  const navRef = useRef(null);
  const dispatch = useDispatch();
  /**
   * Toggles the menu open or closed
   */
  const toggleMenu = () => {
    const trackingAction = menuOpen ? 'close' : 'open';
    $t('track', 'trending_nav', { action: trackingAction });
    setMenuOpen(!menuOpen);
  };
  /**
   * Sets the menuOpen state to false, closing the menu
   */
  const closeMenu = () => {
    if (!menuOpen) return; // fallback for outside click handler overstepping
    $t('track', 'trending_nav', { action: 'close' });
    setMenuOpen(false);
  };

  /**
   * Closes the menu when something outside of the menu is clicked
   * @param {Event} e The mouse down event
   */
  const handleClickOutside = (e) => {
    const { current } = navRef;
    if (
      !menuOpen // if the menu is not open we don't care about clicks
      || !e // if no event data
      || !current // if no ref
      || current.contains(e.target) // if the click is within the ref element
    ) return;

    closeMenu();
  };
  /**
   * Handles the Escape keydown event
   * @param {KeyboardEvent} e The keydown event
   */
  const handleEscapeKey = (e) => {
    if (e.key === 'Escape') {
      closeMenu();
    }
  };

  /**
   * Attaches the event listeners for the trending nav
   * @function attachAccordionListeners
   * @description Attaches event listeners for click outside of the nav and keydown for the Escape key
   */
  const attachAccordionListeners = () => {
    window.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('keydown', handleEscapeKey);
  };

  /**
   * Removes the event listeners for the trending nav
   * @function removeAccordionListeners
   * @description Removes event listeners for click outside of the nav and keydown for the Escape key
   */
  const removeAccordionListeners = () => {
    window.removeEventListener('mousedown', handleClickOutside);
    window.removeEventListener('keydown', handleEscapeKey);
  };

  useEffect(() => {
    attachAccordionListeners();
    return () => removeAccordionListeners();
  }, [menuOpen]);


  useEffect(() => {
    dispatch(loadMostPopularCuratedList({ id: 'tdcl00055667799' }));
    dispatch(loadMostPopularCuratedList({ id: 'tdcl00077889900', type: 'shop' }));
    dispatch(loadMostPopularRecipes());
  }, []);

  const mostPopular = useSelector((state) => state.navbar.mostPopular);
  const darkTheme = useSelector((state) => state.navbar.theme) === 'dark';

  const {
    'trending-nav-show-general': trendingNavShowGeneral,
  } = useContext(FeatureFlagContext);

  const { read, shop, recipe } = mostPopular || {};
  const arrayOfTrending = [recipe, shop];

  // Add read to the array of trending items if the feature flag is enabled
  if (trendingNavShowGeneral) {
    arrayOfTrending.unshift(read);
  }
  return (
    <div
      className={styles.trendingNavContainer}
      data-testid="trendingNavContainer"
      ref={navRef}
    >
      <button
        className={`${styles.trendingNavButton} ${menuOpen ? styles.open : ''} ${darkTheme ? styles.darkTheme : ''}`}
        onClick={toggleMenu}
        data-testid="trendingNavButton"
        type="button"
      >
        <svg width="23" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d={drawnPath} />
        </svg>

      </button>
      {menuOpen
        && (
          <nav
            className={styles.trendingNav}
            data-testid="trendingNavMenu"
          >
            <header className={styles.trendingHeader}>
              <button
                className={styles.closeButton}
                onClick={closeMenu}
                type="button"
                data-testid="trendingNavCloseButton"
              >
                <IconClose />
              </button>
            </header>
            <div className={styles.sectionsContainer} data-testid="trendingSection">

              {arrayOfTrending.map((obj, i) => (
                <section
                  className={styles.trendingSection}
                  key={`obj.title-${obj.title}`}
                  data-testid={`trendingSection-${i}`}
                  data-activity-map={obj.dataActivityMap}
                >
                  <h4 className={styles.trendingSectionTitle}>{obj.title}</h4>
                  {obj.items.map((trendingItem) => (
                    <div className={styles.trendingOption}>
                      <div className={styles.bookmarkContainer}>
                        <Save
                          contentId={trendingItem.id}
                          contentType={trendingItem.contentType}
                          additionalClasses={styles.trendingOptionSave}
                        />
                      </div>
                      <Link
                        className={styles.trendingOptionLink}
                        href={trendingItem.url}
                      >
                        <div className={styles.linkContainer}>

                          {trendingItem.headline}
                        </div>
                      </Link>

                    </div>
                  ))}
                </section>
              ))}

            </div>
          </nav>

        )}
    </div>
  );
}

export { TrendingNav };
