import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import serialize from 'serialize-javascript';
import get from 'lodash.get';
import dynamic from 'next/dynamic';

import Breakpoints from 'lib/Breakpoints';
import { adReady } from 'lib/adReady';
import { layoutContext as LayoutContextPropType } from 'lib/CustomPropTypes';
import { FeatureFlagContext } from 'lib/ContextTypes';
import AdSizes from 'lib/AdSizes.json';
import { stub as $t } from '@nbcnews/analytics-framework';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { CURATOR_AD_PLACEHOLDER } from 'lib/brandFeatures';
import { VIEW } from 'lib/view';
import './styles.themed.scss';

const FIRST_AD_TRACKING_EVENT = 'ramen_ads_first_queue_time';

if (typeof $t !== 'undefined') {
  $t('register', FIRST_AD_TRACKING_EVENT, { allowDuplicate: true });
}

const block = 'ad';

const mapStateToProps = ({
  front: { curation: { adsDisabled, pageRoute } },
  shared: { pageView, view },
  router: { path },
}) => ({
  adsDisabled,
  pageView,
  pageRoute,
  path,
  view,
});

@connect(mapStateToProps)
class Ad extends React.Component {
  static isFirstAdTracked = false;

  mpsAd = null;

  static propTypes = {
    slot: PropTypes.string.isRequired,
    targeting: PropTypes.shape({}),
    renderOnView: PropTypes.bool,
    handler: PropTypes.func,
    adClass: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    sticky: PropTypes.bool,
    offset: PropTypes.number,
    packageId: PropTypes.string,
    refreshInterval: PropTypes.number,
    adsDisabled: PropTypes.bool,
    activeTab: PropTypes.bool,
    pkgClassName: PropTypes.string, // ad can be either a package or regular component
    pageView: PropTypes.string,
    pageRoute: PropTypes.string,
    path: PropTypes.string,
    manuallyCuratedAd: PropTypes.bool,
    view: PropTypes.string,
    offsetViewport: (props, propName, componentName) => {
      // only validate offsetViewport for boxinline.
      const { slot } = props;
      const value = props[propName];
      if (slot === 'boxinline' && (!value || Number.isNaN(value))) {
        return new Error(`Invalid prop ${propName} supplied to ${componentName}`);
      }
      return null;
    },
    testId: PropTypes.string,
  };

  static contextTypes = {
    ...LayoutContextPropType,
    vertical: PropTypes.string,
  };

  // eslint-disable-next-line consistent-return
  componentDidMount() {
    if (!this.showAd()) {
      return null;
    }

    const { adConfigEl } = this;
    const { handler = undefined } = this.props;

    adReady((Mps, createAd) => {
      const ad = createAd(adConfigEl);

      if (typeof handler === 'function') {
        handler(ad, Mps);
      }

      ad.onRender(() => {
        try {
          if (!Ad.isFirstAdTracked) {
            Ad.isFirstAdTracked = this.trackFirstAd();
          }
        } catch (e) {
          if (typeof console === 'object' && typeof console.error === 'function') {
            console.error('ad.on() listener, error thrown while trying to track first ad: ', e);
          }
        }
      });
    });
  }

  shouldComponentUpdate() {
    return false;
  }

  getPageView = () => {
    const { pageView = '', pageRoute = '' } = this.props;
    if (pageView === 'front' && pageRoute === '/') {
      return 'cover';
    }
    return pageView;
  }

  getBreakpoint = () => {
    if (Breakpoints.isS()) {
      return 'mobile';
    }
    return 'desktop';
  }

  trackFirstAd = () => {
    // TODO figure out why the following error is sometimes thrown in IE,
    // why is adQueueTracker sometimes null/undefined:
    // TypeError: Unable to get property 'firstAdQueueTime' of undefined or null reference
    const firstAdQueueTimeGetter = get(window, 'adQueueTracker.firstAdQueueTime', () => {});
    const firstAdQueueTime = typeof firstAdQueueTimeGetter === 'function'
      ? firstAdQueueTimeGetter()
      : null;

    if (firstAdQueueTime) {
      const { queueTime, slot } = firstAdQueueTime;
      $t('track', FIRST_AD_TRACKING_EVENT, {
        slot,
        queueTime: Math.round(queueTime) / 1000,
        pageView: this.getPageView(),
        breakpoint: this.getBreakpoint(),
      });

      return true;
    }
    return false;
  }

  showAd() {
    const {
      adsDisabled = false,
      manuallyCuratedAd = false,
      view = null,
      slot,
    } = this.props;

    // nonPackageAdsDisabled comes from the layout context
    // ads added outside of the layout like the topbanner
    // won't be affected by this
    const {
      nonPackageAdsDisabled,
    } = this.context;

    if (adsDisabled) {
      return false;
    }

    // disable all ads for Start Today app, except for sponsorships
    if (view === VIEW.START_TODAY_APP && slot !== 'sponsorlogo') {
      return false;
    }

    return !(nonPackageAdsDisabled && !manuallyCuratedAd);
  }

  render() {
    const {
      adClass = null,
      slot,
      renderOnView = true,
      offset = undefined,
      refreshInterval = undefined,
      packageId = null,
      targeting = {},
      activeTab = true,
      pkgClassName = block,
      offsetViewport = undefined,
      sticky = false,
      path = '',
      testId = null,
    } = this.props;

    // dynamically load component importing styles specific to a vertical
    const { vertical } = this.context;
    let VerticalStyle = null;
    if (vertical === 'today') {
      // eslint-disable-next-line import/no-unresolved
      VerticalStyle = dynamic(() => import('./Style/Today'));
    }

    if (!this.showAd()) {
      return null;
    }

    // putting a check here to make sure only 'boxinline' ad is able to pass the
    // offsetViewport property down and exclude other ads, such as boxrail
    const offsetViewportCheck = offsetViewport && slot === 'boxinline' ? offsetViewport : null;

    const sizes = AdSizes[slot] || [];

    let textAlignmentClass = null;
    if (!['topbanner', 'sponsorlogo', 'interstitial', 'midresponsive'].includes(slot)) {
      textAlignmentClass = 'ad--text-alignment';
    }

    const addAdContainer = !['topbanner', 'sponsorlogo', 'interstitial'].includes(slot);

    const isBoxinline = slot === 'boxinline';
    // TODO: we shouldn't be calling out slots here

    const showPreview = getFeatureConfigForBrand(
      CURATOR_AD_PLACEHOLDER,
      vertical,
    ) && path.includes('__preview/');

    return (
      <>
        {VerticalStyle && <VerticalStyle />}
        <FeatureFlagContext.Consumer>
          {(({ 'boxinline-ad-vertical-spacing': boxinlineMinHeight }) => {
            const adClasses = classNames(
              pkgClassName,
              adClass,
              textAlignmentClass,
              {
                'ad-container': addAdContainer,
                'ad--sticky': sticky,
                'ad--front': packageId !== null,
                boxinlineAd: isBoxinline,
                'boxinlineAd--50': isBoxinline && boxinlineMinHeight === 50,
                'boxinlineAd--250': isBoxinline && boxinlineMinHeight === 250,
                bottomLineBoxLineAd: vertical === 'news' && path === '/',
                // makes the ad block visible in Curator preview view
                'ad--preview': showPreview,
              },
              'dn-print',
            );
            return (
              <div
                ref={(ad) => { this.ad = ad; }}
                className={adClasses}
                data-packageid={packageId}
                data-testid={testId || 'ad__container'}
              >
                <div
                  ref={(el) => { this.adConfigEl = el; }}
                  data-mps="true"
                  data-slot={slot}
                  data-sizes={serialize(sizes)}
                  data-offset={offset}
                  data-refresh-interval={refreshInterval}
                  data-render-on-view={renderOnView}
                  data-targeting={serialize(targeting)}
                  data-active-tab={activeTab}
                  data-offset-viewport={offsetViewportCheck}
                  data-testid="ad__container__inner"
                />
              </div>
            );
          })}
        </FeatureFlagContext.Consumer>
      </>
    );
  }
}

export default Ad;
