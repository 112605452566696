import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { block as parentBlock } from './helper';

const block = `${parentBlock}__map`;

const Step = ({ active, text }) => (
  <div
    className={classNames(
      `${block}__item`,
      { [`${block}__item--active`]: active },
    )}
    data-testid={`${block}__item`}
  >
    {text}
  </div>
);

Step.propTypes = {
  active: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};

function ProviderSelectMap({
  step2 = false,
  step3 = false,
}) {
  return (
    <div className={block}>
      <Step
        active // always active
        text="1. Select Provider"
      />
      <Step
        active={step2}
        text="2. Verify Account"
      />
      <Step
        active={step3}
        text="3. Watch TV"
      />
    </div>
  );
}

ProviderSelectMap.propTypes = {
  step2: PropTypes.bool,
  step3: PropTypes.bool,
};


export default ProviderSelectMap;
