import React from 'react';
import PropTypes from 'prop-types';
import { providerGenericPropType } from 'lib/CustomPropTypes/TVE';

import { block as parentBlock } from './helper';

const block = `${parentBlock}__select`;

function ProviderSelectOptions({
  onChange = Function.prototype,
  options = [],
}) {
  return (
    <div className={block}>
      <select onChange={onChange} data-testid={block}>
        <option>Select TV Provider</option>
        {options.map((mvpd) => (
          <option
            key={`option-${mvpd.id}`}
            value={mvpd.id}
          >
            {mvpd.displayName}
          </option>
        ))}
      </select>
      <span className={`${block}__down icon icon-angle-down`} />
    </div>
  );
}

ProviderSelectOptions.propTypes = {
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape(providerGenericPropType),
  ),
};

export default ProviderSelectOptions;
