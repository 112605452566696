import React, { useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { VerticalContext } from 'lib/ContextTypes';
import { ENABLE_ELECTIONS_NAV_THEME } from 'lib/brandFeatures';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import loadScript from 'lib/loadScript';

import styles from './styles.module.scss';

const MIN_NAV_HEIGHT = 30;
const MAX_NAV_HEIGHT = 100;

export const NAV_HEIGHT_CSS_VAR = '--election-nav-height';

export const setElectionNavHeightCssVar = (height, previousHeight) => {
  // Don't update height if the previous height is the same as new height
  if (height === previousHeight) {
    return false;
  }

  // Don't update height if height not within min & max thresholds
  const heightAsInt = Number.parseInt(height, 10);
  if (heightAsInt < MIN_NAV_HEIGHT || heightAsInt > MAX_NAV_HEIGHT) {
    return false;
  }

  // Don't update height if the existing css var value is the same
  const root = document.querySelector(':root');
  const currentHeight = root.style.getPropertyValue(NAV_HEIGHT_CSS_VAR);
  const newHeight = `${height}px`;
  if (currentHeight === newHeight) {
    return false;
  }

  root.style.setProperty(NAV_HEIGHT_CSS_VAR, newHeight);
  return true;
};

export const pymLoadScriptHandler = (embedID, embedURL) => {
  if (window.pym) {
    // Strip Pym query string params from url that might have been copied.
    const url = embedURL.replace(/&(childId|parentUrl)=[^&]+/g, '');

    // Pym adds iframe and establishes communication for controlling height.
    const parent = new window.pym.Parent(embedID, url);

    // Set elections css height var for layout calculations
    let previousHeight = 0;
    parent.onMessage('height', (height) => {
      setElectionNavHeightCssVar(height, previousHeight);
      previousHeight = height;
    });

    return parent;
  }

  return false;
};

function ElectionsNav({
  year = '2022',
}) {
  const embedID = 'electionsNav';
  const vertical = useContext(VerticalContext);
  const { firecrackerEmbedURL } = getFeatureConfigForBrand(ENABLE_ELECTIONS_NAV_THEME, vertical);
  const embedURL = firecrackerEmbedURL[year];
  const pymParentRef = useRef();
  const isNavbarActive = useSelector(({ navbar: { active } }) => active);

  useEffect(() => {
    loadScript('https://nodeassets.nbcnews.com/cdnassets/pym/1.3.2/pym.min.js')
      .then(() => pymLoadScriptHandler(embedID, embedURL))
      .then((pymParent) => {
        pymParentRef.current = pymParent;
      });
  }, []);

  useEffect(() => {
    pymParentRef.current?.sendMessage(
      'navStuckChange',
      JSON.stringify({ stuck: isNavbarActive }),
    );
  }, [isNavbarActive]);

  return (
    <div
      className={styles.electionsNav}
      data-test="elections-nav"
      data-testid="elections-nav"
      id={embedID}
    />
  );
}

ElectionsNav.propTypes = {
  year: PropTypes.string,
};


export { ElectionsNav };
