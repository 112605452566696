import React from 'react';
import PropTypes from 'prop-types';
import { notifyErrorMap } from 'lib/tve';
import classnames from 'classnames';
import './styles.themed.scss';

const colorBG = '#ffb4b4';
const colorText = '#911010';

const block = 'tve-notice';

function TVENotice({
  error,
  onClick,
}) {
  return (
    <div
      className={classnames('relative',
        'df',
        'justify-center',
        'items-center',
        'h3',
        'founders-cond',
        'f6',
        'f7-l',
        'b',
        'lh-none',
        'pl5',
        'pr10',
        'ph0-m')}
      data-test="tve-notice"
      data-testid="tve-notice"
      style={{
        backgroundColor: colorBG,
        color: colorText,
      }}
    >
      <span
        className={`${block}__label`}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: notifyErrorMap[error] || 'There was a problem.',
        }}
      />
      <button
        className="absolute right2 b-none bg-transparent p0 m0"
        onClick={onClick}
        style={{
          color: colorText,
        }}
        type="button"
      >
        <span className="icon icon-close" />
      </button>
    </div>
  );
}

TVENotice.propTypes = {
  error: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};


export default TVENotice;
