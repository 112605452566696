/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React from 'react';

const LogoNewsSpecialReport = ({
  block = 'mini-player',
}) => (
  <svg className={`${block}__logo`} data-testid="mini-player__logo--news-special-report" viewBox="0 0 212 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M29.4186 6.47649H29.3539V13.2715H26.4244V1.8916H29.8927L33.8988 8.68754H33.9635V1.8916H36.8947V13.2715H33.4265L29.4186 6.47649Z" fill="white" />
      <path d="M47.0719 1.8916V4.29818H41.1606V6.28184H45.818V8.68754H41.1606V10.8667H47.234V13.2715H38.1956V1.8916H47.0719Z" fill="white" />
      <path d="M55.6694 6.52604H55.5719L53.8458 13.2715H50.817L47.8503 1.8916H50.9278L52.4909 8.68754H52.5556L54.2817 1.8916H57.034L58.7495 8.68754H58.8151L60.3782 1.8916H63.2138L60.2285 13.2715H57.314L55.6694 6.52604Z" fill="white" />
      <path d="M63.4359 5.30865C63.4359 3.16308 65.2268 1.651 68.0278 1.651C70.4699 1.651 72.2935 2.78882 72.7826 4.67515L70.1606 5.25999C69.8673 4.39556 69.1514 3.89301 68.0933 3.89301C67.1 3.89301 66.4319 4.31505 66.4319 5.04322C66.4319 7.31974 73.0432 4.83176 73.0432 9.70951C73.0432 11.9533 71.0937 13.514 68.337 13.514C65.2595 13.514 63.3216 12.1648 62.7199 9.72632L65.4226 9.28393C65.8134 10.5359 66.7739 11.2676 68.1252 11.2676C69.3144 11.2676 70.0472 10.6987 70.0472 9.96698C70.0472 7.69754 63.4359 10.1837 63.4359 5.30865Z" fill="white" />
      <path d="M12.9738 10.6961C12.9738 10.6961 13.0624 10.5191 13.1031 10.4307C13.2437 10.1145 13.3843 9.79893 13.5249 9.48395L13.633 9.24063L15.8554 4.24698C16.224 3.60729 16.4332 2.67297 16.0787 1.96161C15.9338 1.6385 15.7182 1.3519 15.4478 1.12285C15.1752 0.901895 14.8561 0.745538 14.5143 0.665541C14.1724 0.585544 13.8169 0.58399 13.4744 0.660997C13.2798 0.704516 13.0928 0.776992 12.9197 0.875996C12.5653 1.06359 12.2686 1.34375 12.0612 1.68656C11.8538 2.02938 11.7435 2.42199 11.7421 2.8225L11.7704 2.85169L12.7363 2.86143C12.7992 2.90478 12.9011 2.89947 12.9197 2.99149C12.5272 3.29142 11.8564 3.55863 11.9405 4.18328L12.9153 10.3493C12.9374 10.466 12.9738 10.6961 12.9738 10.6961Z" fill="white" />
      <path d="M9.34082 9.45562C9.49501 9.79802 9.65008 10.1378 9.81844 10.4731C9.85211 10.543 9.92212 10.681 9.92212 10.681C9.92212 10.681 9.98149 10.3705 10.0036 10.2271L11.1015 3.20204C11.171 2.69605 11.0703 2.18137 10.8153 1.73863C10.6732 1.50711 10.4918 1.30203 10.2792 1.13256C9.41614 0.442437 8.08342 0.452169 7.29301 1.25377C6.98712 1.54567 6.76946 1.91747 6.66481 2.32682C6.56016 2.73618 6.57273 3.16665 6.70108 3.56922C7.52162 5.39775 8.34246 7.22776 9.1636 9.05924C9.22297 9.19107 9.28145 9.32379 9.34082 9.45562Z" fill="white" />
      <path d="M13.0128 12.2656L13.2326 12.1249L18.1452 8.75923L18.2542 8.6796L19.073 8.11866C19.4513 7.83288 19.8988 7.61522 20.17 7.17903C20.6068 6.55969 20.6937 5.54662 20.3011 4.87862C19.9848 4.23804 19.3175 3.8045 18.6547 3.68594C18.2253 3.62022 17.7861 3.67667 17.3874 3.84881C16.9887 4.02096 16.6466 4.30182 16.4004 4.65919C16.3606 4.71759 16.3234 4.77775 16.287 4.83615C15.2184 7.24539 14.0407 9.61392 13.0802 12.0647L13.0128 12.2656Z" fill="white" />
      <path d="M4.7342 8.74864C6.40453 9.89884 9.87368 12.2656 9.87368 12.2656L9.74696 11.9356L6.68277 5.11311L6.63847 5.0149C6.39525 4.48872 5.9625 4.07329 5.42638 3.8513C4.89026 3.6293 4.29007 3.61703 3.7453 3.81691C3.41869 3.94804 3.1262 4.15158 2.88995 4.41215C2.65369 4.67273 2.47985 4.98351 2.38156 5.32103C2.26535 5.73552 2.27279 6.17487 2.40296 6.58521C2.53312 6.99555 2.78037 7.35906 3.11438 7.63117L4.47457 8.56991L4.7342 8.74864Z" fill="white" />
      <path d="M12.75 13.2716H19.9958C20.6843 13.2716 21.3081 12.9716 21.784 12.3948C21.8194 12.3514 21.8513 12.3063 21.8832 12.2576C22.2802 11.6551 22.3706 10.7739 22.0604 10.1183C21.8416 9.63429 21.3764 9.15032 20.881 8.95567C20.2412 8.65043 19.348 8.71856 18.7614 9.09635L15.0769 11.656L14.8723 11.7976L12.9051 13.1645L12.75 13.2716Z" fill="white" />
      <path d="M1.0912 12.3956C1.30945 12.6747 1.5898 12.8991 1.91004 13.0511C2.23027 13.2032 2.58158 13.2786 2.9361 13.2715H10.1252L9.34627 12.7309L8.01 11.8037L4.11109 9.09718C3.52448 8.71938 2.63127 8.65479 1.9915 8.9565C1.49705 9.15115 1.03095 9.63512 0.812077 10.1191C0.607093 10.5907 0.570494 11.1182 0.708401 11.6135C0.780536 11.8978 0.910876 12.1641 1.0912 12.3956V12.3956Z" fill="white" />
    </g>
    <path d="M78 9.21213C78 11.9686 80.0492 13.51 82.8601 13.51C85.3264 13.51 87.1398 12.295 87.1398 10.1914C87.1398 8.35981 86.088 7.45308 84.0025 7.05412L81.9352 6.65516C80.6114 6.40128 79.8679 6.03859 79.8679 4.91424C79.8679 3.64483 80.7927 2.86504 82.443 2.86504C84.329 2.86504 85.4714 3.93498 85.5258 5.82097L86.9585 5.74843C86.9403 3.17333 85.2901 1.65002 82.4248 1.65002C79.9223 1.65002 78.3446 3.01012 78.3446 5.00492C78.3446 6.7821 79.5052 7.68883 81.1917 8.01525L83.4585 8.45048C84.9455 8.74064 85.6346 9.2484 85.6346 10.3002C85.6346 11.5515 84.5647 12.2769 82.8601 12.2769C80.9378 12.2769 79.5052 11.2069 79.4145 9.12146L78 9.21213Z" fill="white" />
    <path d="M90.4321 7.74323V3.15519H92.9891C94.9839 3.15519 95.945 3.97125 95.945 5.44015C95.945 6.92718 94.9839 7.74323 92.9891 7.74323H90.4321ZM90.4321 9.03079H93.0253C95.8543 9.03079 97.4683 7.68883 97.4683 5.44015C97.4683 3.2096 95.8543 1.86764 93.0253 1.86764H88.8725V13.2924H90.4321V9.03079Z" fill="white" />
    <path d="M107.092 11.9867H100.473V8.05152H105.605V6.76397H100.473V3.17333H106.928V1.86764H98.9492V13.2924H107.092V11.9867Z" fill="white" />
    <path d="M107.863 7.58002C107.863 11.0981 110.311 13.51 113.466 13.51C116.73 13.51 118.58 11.4971 118.798 8.55929L117.274 8.48675C117.147 10.5904 115.824 12.1681 113.466 12.1681C111.145 12.1681 109.476 10.3909 109.476 7.58002C109.476 4.76917 111.145 2.99198 113.466 2.99198C115.824 2.99198 117.147 4.56969 117.274 6.6733L118.798 6.60076C118.58 3.66296 116.73 1.65002 113.466 1.65002C110.311 1.65002 107.863 4.06192 107.863 7.58002Z" fill="white" />
    <path d="M120.466 1.86764V13.2924H122.044V1.86764H120.466Z" fill="white" />
    <path d="M128.028 1.86764L123.368 13.2924H124.909L126.251 9.93752H131.456L132.834 13.2924H134.502L129.751 1.86764H128.028ZM128.79 3.60856H128.862L130.948 8.68623H126.741L128.79 3.60856Z" fill="white" />
    <path d="M135.82 1.86764V13.2924H143.763V11.9867H137.398V1.86764H135.82Z" fill="white" />
    <path d="M157.693 13.2924C157.312 12.8753 157.257 12.2225 157.112 10.5541C157.003 9.21213 156.332 8.35981 154.936 8.08779C156.332 7.92458 157.529 6.99972 157.529 5.24067C157.529 3.2096 156.133 1.86764 153.231 1.86764H148.589V13.2924H150.13V8.68623H153.358C154.918 8.68623 155.516 9.53856 155.589 10.808C155.68 12.1499 155.843 12.9841 156.06 13.2924H157.693ZM150.13 7.43495V3.15519H153.141C155.081 3.15519 155.952 3.98938 155.952 5.29507C155.952 6.70957 155.081 7.43495 153.141 7.43495H150.13Z" fill="white" />
    <path d="M167.587 11.9867H160.968V8.05152H166.1V6.76397H160.968V3.17333H167.424V1.86764H159.445V13.2924H167.587V11.9867Z" fill="white" />
    <path d="M170.94 7.74323V3.15519H173.497C175.491 3.15519 176.453 3.97125 176.453 5.44015C176.453 6.92718 175.491 7.74323 173.497 7.74323H170.94ZM170.94 9.03079H173.533C176.362 9.03079 177.976 7.68883 177.976 5.44015C177.976 3.2096 176.362 1.86764 173.533 1.86764H169.38V13.2924H170.94V9.03079Z" fill="white" />
    <path d="M190.301 7.58002C190.301 4.06192 187.943 1.65002 184.625 1.65002C181.288 1.65002 178.949 4.06192 178.949 7.58002C178.949 11.0981 181.288 13.51 184.625 13.51C187.943 13.51 190.301 11.0981 190.301 7.58002ZM188.669 7.58002C188.669 10.3727 187.091 12.1681 184.625 12.1681C182.158 12.1681 180.563 10.3727 180.563 7.58002C180.563 4.7873 182.158 2.99198 184.625 2.99198C187.091 2.99198 188.669 4.7873 188.669 7.58002Z" fill="white" />
    <path d="M201.081 13.2924C200.7 12.8753 200.646 12.2225 200.501 10.5541C200.392 9.21213 199.721 8.35981 198.324 8.08779C199.721 7.92458 200.918 6.99972 200.918 5.24067C200.918 3.2096 199.521 1.86764 196.62 1.86764H191.977V13.2924H193.519V8.68623H196.747C198.306 8.68623 198.905 9.53856 198.977 10.808C199.068 12.1499 199.231 12.9841 199.449 13.2924H201.081ZM193.519 7.43495V3.15519H196.529C198.47 3.15519 199.34 3.98938 199.34 5.29507C199.34 6.70957 198.47 7.43495 196.529 7.43495H193.519Z" fill="white" />
    <path d="M207.413 13.2924V3.17333H211.638V1.86764H201.701V3.17333H205.853V13.2924H207.413Z" fill="white" />
    <defs>
      <clipPath id="clip0">
        <rect width="73.0435" height="13.9122" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

LogoNewsSpecialReport.propTypes = {
  block: PropTypes.string,
};


export default LogoNewsSpecialReport;
