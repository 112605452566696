/* eslint-disable max-len */
import PropTypes from 'prop-types';
import React from 'react';

const LogoNewsNow = ({
  block = 'mini-player',
}) => (
  <svg className={`${block}__logo`} data-testid="mini-player__logo--news-now" viewBox="0 0 141 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M28.8396 7.32514H28.7789V13.7691H26.017V2.97827H29.2847L33.0593 9.42132H33.1239V2.97827H35.8858V13.7691H32.6142L28.8396 7.32514Z" fill="white" />
      <path d="M37.1101 2.97827H42.3302C44.647 2.97827 46.1656 3.87187 46.1656 5.98427C46.1656 7.03331 45.5363 7.89162 44.4629 8.21969V8.28072C45.5211 8.481 46.4266 9.25252 46.4266 10.7317C46.4266 12.8746 44.908 13.7691 42.5912 13.7691H37.1101V2.97827ZM39.9034 7.20211H41.7608C42.8342 7.20211 43.2793 6.90933 43.2793 6.23031C43.2793 5.5513 42.819 5.22895 41.7608 5.22895H39.9024L39.9034 7.20211ZM39.9034 9.26778V11.5185H41.8206C43.0174 11.5185 43.5394 11.179 43.5394 10.3931C43.5394 9.60729 43.0326 9.26778 41.8206 9.26778H39.9034Z" fill="white" />
      <path d="M46.975 8.37329C46.975 5.07451 49.2766 2.74658 52.5444 2.74658C55.1374 2.74658 57.0555 4.08745 57.8689 6.47642L55.2446 7.0162C54.7701 5.84413 53.8029 5.16606 52.6365 5.16606C50.9641 5.16606 49.8897 6.41538 49.8897 8.37233C49.8897 10.3293 50.9793 11.5786 52.6365 11.5786C53.8181 11.5786 54.7539 10.911 55.2446 9.72942L57.8689 10.2682C57.0555 12.6887 55.2114 13.999 52.6061 13.999C49.2918 14 46.975 11.6873 46.975 8.37329Z" fill="white" />
      <path d="M64.4348 7.32514H64.3741V13.7691H61.6112V2.97827H64.88L68.6546 9.42132H68.7153V2.97827H71.4772V13.7691H68.2094L64.4348 7.32514Z" fill="white" />
      <path d="M81.067 2.97827V5.25947H75.4967V7.14012H79.8854V9.42132H75.4967V11.487H81.2198V13.7691H72.7016V2.97827H81.067Z" fill="white" />
      <path d="M89.1677 7.37187H89.0728L87.4461 13.7691H84.5949L81.8027 2.97827H84.7031L86.179 9.42132H86.2407L87.8675 2.97827H90.4604L92.0739 9.42132H92.1356L93.6086 2.97827H96.2785L93.4634 13.7691H90.7176L89.1677 7.37187Z" fill="white" />
      <path d="M96.4862 6.21513C96.4862 4.18094 98.1737 2.74756 100.812 2.74756C103.114 2.74756 104.833 3.82617 105.293 5.61432L102.825 6.16936C102.549 5.35206 101.876 4.87426 100.877 4.87426C99.9409 4.87426 99.3117 5.27481 99.3117 5.96908C99.3117 8.12726 105.542 5.76881 105.542 10.3932C105.542 12.5199 103.7 14 101.107 14C98.2069 14 96.3808 12.7202 95.8133 10.4085L98.3607 9.9917C98.7289 11.179 99.6344 11.8724 100.907 11.8724C102.028 11.8724 102.718 11.3335 102.718 10.6392C102.715 8.48107 96.4862 10.8405 96.4862 6.21513Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M11.5816 1.55078C11.9017 1.03509 12.4337 0.49506 13.0041 0.266307C13.9888 -0.176298 15.3079 0.0137937 16.1098 0.714179C16.8877 1.40975 17.2814 2.36297 17.0946 3.42832C17.2863 3.39447 17.4782 3.27258 17.6798 3.20451C18.8857 2.86909 20.1608 3.23379 20.9338 4.25541C21.5143 4.98501 21.6668 6.1185 21.3173 6.99889C21.1454 7.44156 20.8498 7.84056 20.4657 8.18067L20.5052 8.21991C21.6275 8.27321 22.7299 9.1346 23.0696 10.1898C23.4391 11.2555 23.1138 12.3935 22.3262 13.1717C21.6814 13.7994 20.8547 14.0183 19.9343 13.9989H3.22928C1.85718 14.0291 0.79724 13.4283 0.226852 12.2232C-0.22092 11.1873 0.000594184 9.86427 0.836942 9.0759C1.36392 8.53099 2.02359 8.3077 2.73707 8.19576C1.92963 7.45614 1.54119 6.44422 1.76745 5.35961C1.92963 4.47921 2.68775 3.58874 3.55892 3.28254C4.32724 2.98083 5.36554 3.03445 6.06894 3.44296L6.0937 3.41387C5.88258 2.21719 6.41866 1.13713 7.42252 0.475858C8.31878 -0.12261 9.75093 -0.10816 10.627 0.529289C11.0206 0.767674 11.3455 1.17605 11.5816 1.55078Z" fill="#FFFFFE" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12.9356 13.3565C13.0258 13.3565 13.1158 13.3565 13.2062 13.3565C15.5424 13.3551 17.8785 13.3535 20.2151 13.352C20.9733 13.3714 21.6176 13.055 22.0999 12.4758C22.1359 12.4321 22.1691 12.3861 22.2011 12.3379C22.604 11.7327 22.6955 10.8484 22.3806 10.19C22.1591 9.70363 21.6865 9.21748 21.1847 9.02251C20.5348 8.71618 19.6291 8.7838 19.0335 9.16366C17.7878 10.0204 16.5421 10.8769 15.2961 11.7334C15.2266 11.7811 15.1575 11.8289 15.0884 11.8765C14.423 12.3336 13.758 12.7916 13.0927 13.2487C13.0402 13.2848 12.9876 13.3207 12.9356 13.3565Z" fill="#0DB14B" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.2012 12.3454C13.263 12.3454 13.3727 12.2387 13.4247 12.2039C15.0858 11.0768 16.7469 9.94948 18.408 8.82255C18.446 8.79692 18.4843 8.77098 18.5225 8.74465C18.7994 8.5568 19.0759 8.36883 19.3531 8.18112C19.7372 7.89418 20.1905 7.67538 20.4655 7.23727C20.9091 6.61472 20.9974 5.59805 20.5989 4.92682C20.2742 4.28475 19.5995 3.85203 18.9252 3.73014C18.0135 3.60864 17.148 3.97091 16.6381 4.70571C16.5976 4.76422 16.5594 4.82484 16.5231 4.8881C15.4387 7.30734 14.2438 9.68098 13.27 12.146C13.2438 12.2113 13.2242 12.2789 13.2012 12.3454Z" fill="#0089CF" />
      <path fillRule="evenodd" clipRule="evenodd" d="M13.1619 10.7689C13.2244 10.6966 13.2545 10.5883 13.2934 10.5022C13.436 10.1853 13.5784 9.86837 13.7209 9.55196C13.7577 9.47066 13.7939 9.38942 13.8306 9.30811C14.5823 7.63672 15.334 5.96558 16.0856 4.29451C16.4596 3.65205 16.6712 2.71354 16.3119 1.99883C16.179 1.68216 15.9475 1.40017 15.6721 1.1571C15.1442 0.711023 14.3607 0.537885 13.6702 0.692848C13.4719 0.737353 13.2819 0.808702 13.1073 0.908628C12.3597 1.30307 11.9165 2.05213 11.912 2.86458C11.9215 2.87396 11.9316 2.88353 11.9414 2.89361C12.2679 2.89663 12.5942 2.89997 12.9208 2.90344C12.9847 2.94717 13.088 2.94222 13.1073 3.03444C12.7092 3.33596 12.03 3.60369 12.1136 4.23119C12.4444 6.29477 12.7756 8.359 13.1064 10.4225C13.1252 10.5379 13.1431 10.6533 13.1619 10.7689Z" fill="#645FAA" />
      <path fillRule="evenodd" clipRule="evenodd" d="M9.47632 9.52384C9.63214 9.86742 9.78939 10.2087 9.96022 10.5453C9.99511 10.6155 10.0305 10.6847 10.0655 10.7544C10.0756 10.7492 10.0851 10.7397 10.0806 10.7299C10.1032 10.5864 10.1261 10.4426 10.1485 10.2987C10.5198 7.94704 10.891 5.59549 11.2618 3.24362C11.3455 2.69865 11.2078 2.2073 10.9716 1.77465C10.8298 1.54333 10.6402 1.33564 10.4257 1.16558C9.55286 0.471362 8.20071 0.482664 7.39822 1.28792C6.77818 1.89095 6.5219 2.77192 6.79768 3.61308C7.63026 5.45055 8.46316 7.28808 9.296 9.1251C9.35637 9.25803 9.41615 9.39097 9.47632 9.52384Z" fill="#C9234A" />
      <path fillRule="evenodd" clipRule="evenodd" d="M4.80337 8.81392C6.49751 9.9677 8.19132 11.1215 9.88507 12.2756C9.94231 12.3148 9.97039 12.3453 10.0413 12.3453C9.99118 12.2348 9.94075 12.1242 9.89053 12.0137C8.85391 9.73028 7.81678 7.44609 6.78004 5.16274C6.76489 5.12993 6.75008 5.09685 6.73507 5.06417C6.23824 3.96825 4.93787 3.44832 3.80002 3.86113C3.14562 4.10433 2.5942 4.71731 2.41701 5.37448C2.181 6.24973 2.46626 7.11562 3.16024 7.69456C3.62029 8.00783 4.07982 8.3209 4.53949 8.63397C4.6276 8.69415 4.71552 8.75381 4.80337 8.81392Z" fill="#F37021" />
      <path fillRule="evenodd" clipRule="evenodd" d="M1.10772 12.4758C1.59038 13.055 2.23511 13.3714 2.9929 13.352C5.41955 13.3535 7.84626 13.3553 10.273 13.3565C10.0092 13.1756 9.746 12.9944 9.4827 12.8132C9.0309 12.5028 8.57903 12.1922 8.1271 11.8816C6.80991 10.9756 5.49232 10.0699 4.17441 9.16366C3.57907 8.7838 2.67294 8.71618 2.02354 9.02251C1.52183 9.21748 1.04911 9.70363 0.827397 10.19C0.612901 10.6385 0.587364 11.192 0.719467 11.6905C0.797052 11.9817 0.928375 12.2541 1.10772 12.4758Z" fill="#FDB913" />
      <path d="M140.519 2.75H108.244V14H140.519V2.75Z" fill="#FA3A3A" />
      <path d="M116.604 9.4862V4.69873H118.297V12.3539H116.628L112.975 7.08007V12.3539H111.283V4.69873H113.355L116.604 9.4862Z" fill="white" />
      <path d="M123.161 12.4999C120.936 12.4999 119.174 10.8787 119.174 8.52689C119.174 6.17512 120.933 4.55005 123.161 4.55005C125.388 4.55005 127.136 6.1713 127.136 8.52689C127.136 10.8825 125.37 12.4999 123.161 12.4999ZM123.161 6.18179C121.915 6.18179 121.073 7.10495 121.073 8.52689C121.073 9.94882 121.919 10.872 123.161 10.872C124.402 10.872 125.24 9.94787 125.24 8.52689C125.24 7.10591 124.394 6.18179 123.161 6.18179Z" fill="white" />
      <path d="M134.851 9.83906H134.899L136.217 4.69873H137.996L135.976 12.3539H133.994L132.639 7.23838H132.586L131.232 12.3539H129.284L127.271 4.69873H129.11L130.428 9.82666H130.476L131.827 4.69873H133.495L134.851 9.83906Z" fill="white" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="140.519" height="14" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

LogoNewsNow.propTypes = {
  block: PropTypes.string,
};

export default LogoNewsNow;
