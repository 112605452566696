import { verticalSlugMap } from 'lib/vertical';

import { isBlogArticle } from './isBlogArticle';

/**
 * Boolean function to determine if a given article is a Today article that is not commerce related
 *
 * @param {object} article
 * @param {string} vertical
 * @returns {Boolean}
 */
export const isNonCommerceTodayArticle = (article, vertical) => {
  if (!article || !vertical) return false;

  const {
    today: TODAY,
  } = verticalSlugMap;
  const ecommerceEnabled = article?.ecommerceEnabled ?? null;
  const subTypeIsNotLiveBlogOrBlog = !isBlogArticle(article);
  return vertical === TODAY && subTypeIsNotLiveBlogOrBlog && !ecommerceEnabled;
};
