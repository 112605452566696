import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import WhiteLogo from './logo-white.svg';
import BlackLogo from './logo-black.svg';

import styles from './styles.module.scss';

/**
 * Logo component
 * @param {string} color - defines color of the logo
 * @param {object} active - defines whether logo is active or not
 */
const GlobalCitizenLogo = ({
  color,
  active = false,
}) => {
  const [black, setBlack] = useState(false);
  let testid;

  useEffect(() => {
    if (!active) {
      // Manual Animation for source switch.
      setTimeout(() => {
        setBlack(true);
      }, 1000);
    } else if (active) {
      setBlack(false);
    }
  }, [active]);

  const setColor = () => {
    if (black || color === 'black') {
      testid = 'global-citizen-black-logo';
      return BlackLogo;
    }
    testid = 'global-citizen-white-logo';
    return WhiteLogo;
  };

  return (
    <span className={styles.logo}>
      <img alt="Global Citizen" src={setColor()} data-testid={testid} />
    </span>
  );
};

GlobalCitizenLogo.propTypes = {
  color: PropTypes.string.isRequired,
  active: PropTypes.bool,
};


export { GlobalCitizenLogo };
