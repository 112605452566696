
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.module.scss';

function SelectLogo({
  color,
  active = false,
}) {
  return (
    /* eslint-disable max-len */
    <svg
      className={classNames(styles.logo, { [active]: active, [color]: color })}
      viewBox="0 0 150 43"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M47.698 29.639l2.432-3.323c1.315 1.262 2.695 1.86 4.207 1.86.986 0 1.577-.332 1.577-.93 0-.531-.46-.864-2.498-1.528-2.694-.864-5.06-2.193-5.06-5.317 0-3.322 2.694-5.183 6.047-5.183 2.432 0 4.206.665 6.047 2.06l-2.432 3.589c-1.118-1.13-2.498-1.728-3.55-1.728-.591 0-1.249.266-1.314.864 0 .531.591.864 2.169 1.462 3.55 1.262 5.455 2.193 5.455 5.382 0 3.057-2.169 5.184-6.573 5.184-2.497 0-4.732-.798-6.507-2.392z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M67.746 25.053h10.977c0-.097.006-.235.012-.389.016-.37.035-.838-.012-1.073 0-4.785-3.286-8.24-7.822-8.24-4.864 0-8.282 3.455-8.282 8.373 0 5.05 3.55 8.44 8.414 8.44 3.615 0 5.85-1.263 7.23-2.924l-2.958-3.057c-1.052 1.196-2.432 1.728-3.747 1.728-1.971 0-3.483-.997-3.812-2.858zm6.113-3.123h-6.047c.328-1.662 1.511-2.592 3.155-2.592 1.512 0 2.563.997 2.892 2.592z" />
      <path d="M81.681 8.174h5.127v23.59h-5.127V8.175z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M94.827 25.053h10.977c0-.097.006-.229.012-.377.016-.355.035-.803-.012-1.085 0-4.785-3.286-8.24-7.822-8.24-4.864 0-8.282 3.455-8.282 8.373 0 5.05 3.55 8.44 8.414 8.44 3.615 0 5.85-1.263 7.23-2.924l-2.958-3.057c-1.051 1.196-2.432 1.728-3.746 1.728-1.972 0-3.484-.997-3.813-2.858zm6.113-3.123h-6.047c.329-1.595 1.512-2.592 3.155-2.592 1.512 0 2.564.997 2.892 2.592z" />
      <path d="M107.842 23.724c0-5.117 3.418-8.44 8.545-8.44 2.498 0 5.127.798 6.902 2.858l-3.352 3.323c-.723-.997-1.972-1.529-3.155-1.529-2.235 0-3.813 1.529-3.813 3.788 0 2.326 1.578 3.788 3.813 3.788 1.446 0 2.497-.532 3.418-1.529l3.155 3.323c-1.446 1.662-3.813 2.791-7.165 2.791-4.864 0-8.414-3.389-8.348-8.373zM126.247 26.715v-6.978h-1.841V15.55h1.841V10.3l5.193-.531v5.781h3.878v4.187h-3.878v5.98c0 1.064.197 1.861 1.183 1.861.394 0 1.052-.066 1.577-.332l1.249 3.855c-1.117.598-2.3 1.063-4.535 1.063-2.695 0-4.667-1.795-4.667-5.45zM35.927 16.376c-.178-.449-.444-.808-.888-1.167-.887-.807-2.396-.986-3.461-.358a3.033 3.033 0 00-1.598 2.691h1.331c.09.09.267.09.267.18-.533.448-1.42.807-1.331 1.615l1.42 8.973 3.905-8.794c.532-.807.798-2.153.355-3.14zM27.583 28.4l1.598-10.23c.088-.717-.09-1.435-.355-1.973-.355-.539-.888-1.077-1.51-1.256-1.064-.45-2.484-.27-3.283.628-.799.807-1.154 2.063-.799 3.23l3.994 8.973.355.628zM41.696 20.414c.532.897.443 2.332-.178 3.14-.355.628-.976.897-1.509 1.256l-8.254 5.743.09-.27 4.348-9.96c.621-1.166 1.864-1.794 3.195-1.614.888.269 1.864.807 2.308 1.705zM18.264 24.182l9.142 6.37h.088l-4.526-10.228c-.799-1.346-2.396-1.974-3.816-1.436-.888.36-1.598 1.167-1.864 2.064-.266 1.256.089 2.512.976 3.23zM43.648 30.732c.621-.807.799-2.153.355-3.14-.266-.628-.887-1.346-1.597-1.615-.888-.449-2.042-.36-2.84.18L31.4 31.898h9.763c.976.09 1.864-.36 2.485-1.167zM27.85 31.989l-8.166-5.743c-.799-.538-2.041-.628-2.84-.18-.71.27-1.331.898-1.598 1.616-.443.987-.266 2.333.355 3.14.622.808 1.51 1.257 2.486 1.167h9.762z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M129.492 0H20.509C9.161 0-.068 9.31 0 20.74v.658C0 32.803 9.207 42.132 20.51 42.132h108.983c11.281 0 20.508-9.307 20.508-20.734v-.664C150 9.329 140.794 0 129.492 0zM1.972 20.734c-.062-10.327 8.28-18.74 18.537-18.74h108.983c10.212 0 18.536 8.435 18.536 18.74v.664c0 10.325-8.343 18.74-18.536 18.74H20.509c-10.212 0-18.537-8.435-18.537-18.74v-.664z" />
      <path d="M.002 22.446c.555 11.013 9.58 19.752 20.507 19.752h108.983c10.927 0 19.952-8.739 20.507-19.752l-1.97-.102c-.502 9.957-8.663 17.86-18.537 17.86H20.509c-9.874 0-18.036-7.903-18.538-17.86l-1.969.102z" />
    </svg>
    /* eslint-enable max-len */
  );
}

SelectLogo.propTypes = {
  color: PropTypes.string.isRequired,
  active: PropTypes.bool,
};


export { SelectLogo };
