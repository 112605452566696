import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import { getMiniPlayerData as getMiniPlayerDataAction } from 'redux/modules/navbar';

import Breakpoints from 'lib/Breakpoints';
import { ENABLE_MINI_TEASE } from 'lib/brandFeatures';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { miniPlayerTease as miniPlayerTeasePropType } from 'lib/CustomPropTypes';
import { stripQueryParams } from 'lib/urlUtils';

import tease from './Tease';

import './styles.themed.scss';

const mapStateToProps = ({
  navbar,
  router,
  shared,
}) => ({
  miniPlayerData: navbar.miniPlayerData,
  pageView: shared.pageView,
  path: router.path,
  vertical: shared.vertical,
});

const mapActionToProps = (dispatch) => ({
  getMiniPlayerData: (vertical) => dispatch(getMiniPlayerDataAction(vertical)),
});

/**
 * Implements the MiniPlayerTease page and content.
 * @param {object} props props object
 * @param {fuction} props.getMiniPlayerData function to get mini player data
 * @param {boolean} props.miniPlayerLoaded if feature is enabled
 * @param {object} props.miniPlayerData contains data related to a mini player feature.
 * @param {boolean} props.useLocalTease determines whether to use the 'local' or 'default' for teaseName
 * @param {Function} props.onShowTease function that is called when the wasShown is false, the isShown is true, and the shouldRender function returns true.
 * @param {string} props.pageView the view of the page
 * @param {string} props.path path of the current page
 * @param {string} props.vertical vertical value
*/
function MiniPlayerTease({
  getMiniPlayerData = () => {},
  miniPlayerLoaded = false,
  miniPlayerData = { showTease: false },
  useLocalTease = false,
  onShowTease = () => {},
  pageView,
  path,
  vertical,
}) {
  /**
   * Get enabled configuration value
   */
  const isEnabled = () => getFeatureConfigForBrand(ENABLE_MINI_TEASE, vertical);

  /**
   * Check if the current page is the cover page
   * @returns {boolean}
   */
  const isCover = () => pageView === 'front' && stripQueryParams(path) === '/';

  useEffect(() => {
    if (!miniPlayerLoaded) {
      // Feature enabled
      const enabled = isEnabled();
      // Fetch mini player configuration data
      if (enabled) {
        getMiniPlayerData(vertical);
      }
    }
  }, []);

  /**
   * Function that uses miniPlayerData fetched from static-api to determine if page should render.
   * If there is no miniPlayerData for the vertical, miniplayer will not render.
   * Returns true if page is in includedPageTypes and not in excludedPages.
   * @returns {Boolean}
   */
  const shouldRender = () => {
    if (!isEnabled()) {
      return false;
    }

    if (miniPlayerData) {
      const {
        includedPageTypes,
        excludedPages,
      } = miniPlayerData;

      const isCoverValue = isCover();

      if (includedPageTypes || excludedPages) {
        // Cover Mobile
        if (isCoverValue && typeof includedPageTypes.coverMobile !== 'undefined' && Breakpoints.isS()) {
          return includedPageTypes.coverMobile;
        }
        // Cover
        if (isCoverValue && typeof includedPageTypes.cover !== 'undefined') {
          return includedPageTypes.cover;
        }
        // Front / Article
        if (includedPageTypes[pageView]) {
          // Excluded pages
          const excludedArray = (typeof excludedPages === 'string'
            && excludedPages.split(/\n|\n\r/))
            || [];
          return !excludedArray.includes(stripQueryParams(path));
        }
      }
    }
    return false;
  };

  useEffect(() => {
    if (miniPlayerData?.showTease && shouldRender()) {
      onShowTease();
    }
  }, [miniPlayerData]);

  if (!shouldRender()) {
    return null;
  }
  const {
    brand,
  } = miniPlayerData;

  const miniTeaseConfig = getFeatureConfigForBrand(ENABLE_MINI_TEASE, vertical);
  const teaseName = useLocalTease
    ? get(miniTeaseConfig, [brand, 'local'])
    : get(miniTeaseConfig, [brand, 'default']);

  const TeaseComponent = tease[teaseName];

  if (!TeaseComponent) {
    return null;
  }

  return (
    <TeaseComponent
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...miniPlayerData}
      pageView={pageView}
      path={path}
    />
  );
}

MiniPlayerTease.propTypes = {
  getMiniPlayerData: PropTypes.func,
  miniPlayerLoaded: PropTypes.bool,
  miniPlayerData: PropTypes.oneOfType([
    PropTypes.string,
    miniPlayerTeasePropType,
  ]),
  useLocalTease: PropTypes.bool,
  onShowTease: PropTypes.func,
  pageView: PropTypes.string,
  path: PropTypes.string,
  vertical: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapActionToProps)(
  MiniPlayerTease,
);
