/* eslint-disable max-len */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from 'components/Link';

import { FeatureFlagContext } from 'lib/ContextTypes';

const block = 'mini-tease-local';
function NoticiasAhora({
  isFlagged = true,
  url = 'https://www.telemundo.com/noticias/noticias-telemundo-ahora',
  icid = 'noticias_hp_header',
}) {
  const {
    'use-noticias-en-vivo-link': enabled,
  } = useContext(FeatureFlagContext);

  return enabled && (
    <div
      className={classNames(
        'watch-now',
        block,
        { [`${block}--live`]: isFlagged },
      )}
    >
      <Link data-testid="NoticiasAhoraEnVivo" className={`${block}__link`} href={url} icid={icid} aria-label="Watch Live button">
        <svg className={`${block}__logo`} width="49" height="12" viewBox="0 0 49 12" xmlns="http://www.w3.org/2000/svg" fill="currentColor" aria-labelledby="watch_live_logo">
          <title id="watch_live_logo">EN VIVO</title>
          <path d="M6.72 9.224V11H0.672V0.919999H6.688V2.696H2.784V5H5.76V6.744H2.784V9.224H6.72ZM10.3134 0.919999L13.4334 6.888H13.4974V0.919999H15.4654V11H13.7854L10.3294 4.648H10.2654V11H8.28138V0.919999H10.3134ZM24.9638 11H22.7878L19.8758 0.919999H22.0838L23.9238 7.72H23.9878L25.8438 0.919999H27.8758L24.9638 11ZM29.0783 0.919999H31.2383V11H29.0783V0.919999ZM37.5263 11H35.3503L32.4383 0.919999H34.6463L36.4863 7.72H36.5503L38.4063 0.919999H40.4383L37.5263 11ZM48.6421 5.64V6.28C48.6421 9.272 47.3781 11.176 44.8021 11.176C42.2261 11.176 40.9621 9.272 40.9621 6.28V5.64C40.9621 2.648 42.2261 0.743999 44.8021 0.743999C47.3781 0.743999 48.6421 2.648 48.6421 5.64ZM46.4341 6.712V5.208C46.4341 3.416 45.8901 2.536 44.8021 2.536C43.7141 2.536 43.1701 3.416 43.1701 5.208V6.712C43.1701 8.504 43.7141 9.384 44.8021 9.384C45.8901 9.384 46.4341 8.504 46.4341 6.712Z" fill="currentColor" />
        </svg>
      </Link>
    </div>
  );
}

NoticiasAhora.propTypes = {
  isFlagged: PropTypes.bool,
  url: PropTypes.string,
  icid: PropTypes.string,
};


export { NoticiasAhora };
