import React from 'react';
import PropTypes from 'prop-types';

import FooterDefault from './Default';
import { useOpenOneTrustIfQueryParam } from './useOpenOneTrustIfQueryParam';

/**
 * @type {React.FunctionComponent}
 * @param {object} props
 * @param {{ html?: string[] }} [props.footer]
 * @returns {JSX.Element}
 */
function ServiceFooter(props) {
  const { footer } = props;

  const hasFooter = Boolean(Array.isArray(footer?.html) && footer?.html?.length);

  useOpenOneTrustIfQueryParam(hasFooter);

  // Insert footer html markup
  return (
    <>
      {!hasFooter && <FooterDefault />}
      {hasFooter && (
        <div
          id="hfs-footer"
          className="dn-print"
          data-activity-map="hfs-footer"
          dangerouslySetInnerHTML={{ __html: footer.html[0] }}
        />
      )}
    </>
  );
}

ServiceFooter.propTypes = {
  footer: PropTypes.shape({
    html: PropTypes.arrayOf(
      PropTypes.string,
    ),
  }).isRequired,
};


export { ServiceFooter };
