import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';

import styles from './styles.module.scss';

export const THEME = {
  DARK: 'black',
  LIGHT: 'white',
};

/**
 * Implementing the today logo for brands.
 * @param {object} props component props
 * @param {string} props.vertical brand vertical value
 * @param {string} props.color must be only ONE of the THEME colors, either DARK or LIGHT.
 * @param {boolean} props.active toggles the active styling for the element
 * @param {boolean} props.scrolled toggles the scroll styling for the element
 * @returns {React.ReactElement} the logo for the given vertical
 */
function TodayLogo({
  vertical,
  color = THEME.DARK,
  active = false,
  scrolled = false,
}) {
  /**
   * Renders Sunrise SVG
   * @returns {React.ReactElement} the sunrise svg
   */
  function sunrise() {
    return (
      <svg className={styles.sunrise} xmlns="http://www.w3.org/2000/svg" width="25" height="13" viewBox="0 0 25 13">
        <g fillRule="evenodd">
          <path d="M24.837 12.268C24.57 5.616 19.115.304 12.424.304 5.78.304.352 5.544.017 12.133c-.012.215-.017.43-.017.646h3.221c0-.209.008-.419.022-.625.321-4.805 4.316-8.615 9.181-8.615 4.883 0 8.89 3.84 9.185 8.67.012.189.018.378.018.57h3.22c0-.17-.002-.342-.01-.511" />
          <path d="M12.424 4.462c-4.39 0-7.983 3.432-8.264 7.77-.013.18-.018.362-.018.547h3.22c0-.198.012-.395.035-.588.29-2.525 2.435-4.495 5.027-4.495 2.606 0 4.76 1.989 5.032 4.533.02.181.03.363.03.55h3.22c0-.179-.005-.352-.016-.528-.271-4.348-3.868-7.789-8.266-7.789" />
          <path d="M12.424 8.621a4.145 4.145 0 0 0-4.14 4.16h8.281a4.146 4.146 0 0 0-4.141-4.16" />
        </g>
      </svg>
    );
  }

  return (
    <div
      className={classNames(styles.todayLogo, styles[color], {
        [styles.active]: active,
        [styles.scrolled]: scrolled,
      })}
    >
      {sunrise()}
      <span className={`${styles.logo} icon icon-vertical-${vertical}`} />
    </div>
  );
}

TodayLogo.propTypes = {
  vertical: PropTypes.string.isRequired,
  color: PropTypes.oneOf(Object.values(THEME)),
  active: PropTypes.bool,
  scrolled: PropTypes.bool,
};


export default TodayLogo;
