import React from 'react';
import PropTypes from 'prop-types';

import VerticalLogo, { THEME } from '../Vertical';

const Logo = ({
  color = THEME.DARK,
  active = false,
}) => <VerticalLogo vertical="news" color={color} active={active} />;

Logo.propTypes = {
  color: PropTypes.oneOf(Object.values(THEME)),
  active: PropTypes.bool,
};


export default Logo;
