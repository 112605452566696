import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Link from 'components/Link';

import TodayAllDayLogo from '../Logo/TodayAllDay';

const block = 'mini-tease-local';

function TodayLive({
  isFlagged = true,
  url = 'https://www.today.com/allday',
  icid = 'allday_hp_header',
}) {
  return (
    <div
      className={classNames(
        block,
        { [`${block}--live`]: isFlagged },
      )}
    >
      <Link className={`${block}__link`} href={url} icid={icid}>
        <TodayAllDayLogo block={block} showIcon={false} />
      </Link>
    </div>
  );
}

TodayLive.propTypes = {
  isFlagged: PropTypes.bool,
  url: PropTypes.string,
  icid: PropTypes.string,
};


export { TodayLive };
