import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { providerFeaturedPropType } from 'lib/CustomPropTypes/TVE';

import {
  block as parentBlock,
  gridColCountS,
  gridColCountM,
} from './helper';

const block = `${parentBlock}__featured-grid`;

const Item = ({
  onClick,
  item = null,
}) => {
  if (item) {
    return (
      <button
        type="button"
        onClick={onClick}
        value={item.id}
        className={`${parentBlock}__button ${block}__button__provider`}
        data-testid={`${parentBlock}__featured-grid-item`}
      >
        <img src={item.logo2xWhite} alt={item.displayName} />
      </button>
    );
  }
  return null;
};

Item.propTypes = {
  item: PropTypes.shape(providerFeaturedPropType),
  onClick: PropTypes.func.isRequired,
};

function ProviderSelectFeaturedGrid({
  featured = [],
  onClick = Function.prototype,
}) {
  const itemsS = Math.ceil(featured.length / gridColCountS) * gridColCountS;
  const itemsM = Math.ceil(featured.length / gridColCountM) * gridColCountM;

  const items = [];
  for (let i = 0; i < itemsM; i += 1) {
    const itemClasses = classNames(
      `${block}__provider`,
      {
        [`${block}__provider__hide-mobile`]: i + 1 > itemsS,
      },
    );

    items.push((
      <div key={`provider-${i}`} className={itemClasses} data-testid={`${block}__provider`}>
        <Item item={featured[i]} onClick={onClick} />
      </div>
    ));
  }

  return (
    <>
      <h3 className={`${parentBlock}__heading-h3`}>
        Select your TV Provider Below
      </h3>
      <div className={`${block} clearfix`}>
        {items}
      </div>
    </>
  );
}

ProviderSelectFeaturedGrid.propTypes = {
  featured: PropTypes.arrayOf(
    PropTypes.shape(providerFeaturedPropType),
  ),
  onClick: PropTypes.func,
};

export default ProviderSelectFeaturedGrid;
