import React from 'react';
import classNames from 'classnames';
import { ChevronRightIcon } from 'components/Icon/ChevronRightIcon';

import styles from './styles.module.scss';

/**
 * Overlays a button intended to focus another element
 * @param {object} props
 * @param {string} props.buttonText
 * @param {'fixed' | 'auto'} [props.buttonSize]
 * @param {() => void} props.onClick
 * @param {boolean} [props.showIcon]
 * @param {string} [props.className]
 * @returns {React.JSX}
 */
export function SkipLinkOverlay({
  buttonText,
  onClick,
  buttonSize = 'auto',
  showIcon = false,
  className,
}) {
  return (
    <div className={classNames('skip-link', styles['skip-link'], className)}>
      <button
        className={classNames(
          styles['skip-link__button'],
          styles[`skip-link__button--${buttonSize === 'fixed' ? 'fixed' : 'auto'}`],
        )}
        type="button"
        onClick={onClick}
      >
        <span>{buttonText}</span>
        {showIcon && <ChevronRightIcon className={styles['skip-link__button-icon']} />}
      </button>
    </div>
  );
}
