import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from 'components/Link';

import { MsnbcLiveLogo } from '../Logo/MsnbcLive';

const block = 'mini-tease-local';

function MsnbcLive({
  isFlagged = true,
  url = 'https://www.msnbc.com/live',
  icid = 'tve-mini-tease',
}) {
  return (
    <div
      className={classNames(
        block,
        { [`${block}--live`]: isFlagged },
      )}
      data-testid="msnbc-live-tease"
    >
      <Link className={`${block}__link`} href={url} icid={icid}>
        <MsnbcLiveLogo block={block} />
      </Link>
    </div>
  );
}

MsnbcLive.propTypes = {
  isFlagged: PropTypes.bool,
  url: PropTypes.string,
  icid: PropTypes.string,
};


export { MsnbcLive };
