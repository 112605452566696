import { TAX_CONSTANTS } from 'lib/taxonomy';

/**
 * Boolean function to determine if a given article is a Today article and has a valid section that is not commerce related
 *
 * @param {object} article
 * @returns {Boolean}
 */

export const isValidTodaySection = (article) => {
  if (!article) return false;

  const primarySectionPath = article?.taxonomy?.primarySection?.path ?? '';
  const {
    TODAY: {
      SECTION_HOME,
      SECTION_PETS,
      SECTION_STYLE,
      SECTION_TMRW_TODAY,
      SECTION_ABOUT,
      SECTION_GAMES,
      SECTION_HEALTH,
      SECTION_INFORMATION,
      SECTION_LIFE,
      SECTION_MONEY,
      SECTION_PAID_CONTENT,
      SECTION_PARENTING_GUIDES,
      SECTION_PARENTS,
      SECTION_PODCASTS,
      SECTION_POPCULTURE,
      SECTION_SEASON_OF_KINDNESS,
      SECTION_KINDNESS,
      SECTION_TOGETHER,
      SECTION_VISIT_TODAY,
    },
  } = TAX_CONSTANTS;
  return [
    SECTION_HOME,
    SECTION_PETS,
    SECTION_STYLE,
    SECTION_TMRW_TODAY,
    SECTION_ABOUT,
    SECTION_GAMES,
    SECTION_HEALTH,
    SECTION_INFORMATION,
    SECTION_LIFE,
    SECTION_MONEY,
    SECTION_PAID_CONTENT,
    SECTION_PARENTING_GUIDES,
    SECTION_PARENTS,
    SECTION_PODCASTS,
    SECTION_POPCULTURE,
    SECTION_SEASON_OF_KINDNESS,
    SECTION_KINDNESS,
    SECTION_TOGETHER,
    SECTION_VISIT_TODAY,
  ].includes(primarySectionPath);
};
