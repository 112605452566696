import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param {object} [props]
 * @param {string} [props.className]
 * @returns {React.JSX}
 */
export function ChevronRightIcon({
  className = undefined,
}) {
  return (
    <svg
      width="9"
      height="14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66 7.375.857 1.688 1.75.813l6.695 6.562-6.695 6.563-.893-.875L6.66 7.375Z"
        fill="#3061FF"
      />
    </svg>
  );
}

ChevronRightIcon.propTypes = {
  className: PropTypes.string,
};

