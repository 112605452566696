import MsnbcTVE from './MsnbcTVE';
import NewsNow from './NewsNow';
import { WatchNowTease } from './WatchNow';
import TodayAllDay from './TodayAllDay';
import { MsnbcLive } from './MsnbcLive';
import { TodayLive } from './TodayLive';
import { NoticiasAhora } from './NoticiasAhora';
import { TelemundoAlDia } from './TelemundoAlDia';

export default {
  MsnbcTVE,
  NewsNow,
  WatchNowTease,
  TodayAllDay,
  MsnbcLive,
  TodayLive,
  NoticiasAhora,
  TelemundoAlDia,
};
