import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import getConfig from 'next/config';

import loadScript from 'lib/loadScript';
import {
  ACCESS_ENABLER_OBJ,
  configTVE,
  getAccessEnablerConfig,
  getResourceID,
  notifyErrorMap,
} from 'lib/tve';
import { stub as $t } from '@nbcnews/analytics-framework';

import {
  hideProviderIFrameAction,
  setAuthenticationAction,
  setAuthorizationAction,
  setAccountMetadataAction,
  setTempPassProviderAction,
  showProviderIFrameAction,
} from 'redux/modules/tve';
import { setErrorAction } from 'redux/modules/video/error';

import Notice from 'components/TVE/Notice';
import { ERROR_TYPES } from 'components/TVE/Slate';

const TRACKING_EVENT_NAME = 'tve_auth';

// Connect to store authentication and authorization in global state
const mapStateToProps = ({ router, shared, tve }) => ({
  authenticated: tve.authenticated,
  domain: router.domain,
  hasTempPass: tve.hasTempPass,
  selectedProvider: tve.provider,
  tempPassProviderID: tve.tempPassProviderID,
  vertical: shared.vertical,
});

const mapActionsToProps = (dispatch) => ({
  hideProviderIFrame: () => dispatch(hideProviderIFrameAction()),
  setAuthentication: (payload) => dispatch(setAuthenticationAction(payload)),
  setAuthorization: (payload) => dispatch(setAuthorizationAction(payload)),
  setAccountMetadata: (payload) => dispatch(setAccountMetadataAction(payload)),
  setError: (error) => dispatch(setErrorAction(error)),
  setTempPassProvider: (payload) => dispatch(setTempPassProviderAction(payload)),
  showProviderIFrame: () => dispatch(showProviderIFrameAction()),
});

class TVEAuthProvider extends React.Component {
  static propTypes = {
    authenticated: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
    ]),
    disableBackgroundLogin: PropTypes.bool,
    domain: PropTypes.string.isRequired,
    iframeRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({}),
    ]).isRequired,
    selectedProvider: PropTypes.string,
    setAuthentication: PropTypes.func,
    setAuthorization: PropTypes.func,
    setAccountMetadata: PropTypes.func.isRequired,
    setTempPassProvider: PropTypes.func,
    setError: PropTypes.func,
    showProviderIFrame: PropTypes.func,
    hideProviderIFrame: PropTypes.func,
  };

  static defaultProps = {
    authenticated: null,
    disableBackgroundLogin: false,
    selectedProvider: null,
    setAuthentication: Function.prototype,
    setAuthorization: Function.prototype,
    setTempPassProvider: Function.prototype,
    setError: Function.prototype,
    showProviderIFrame: Function.prototype,
    hideProviderIFrame: Function.prototype,
  };

  constructor(props) {
    super(props);
    this.state = {
      error: null,
    };
  }

  componentDidMount() {
    // Argument of 'false' to return staging configs
    const getAEConfig = getAccessEnablerConfig(process.env.NODE_ENV === 'production');

    const {
      publicRuntimeConfig: {
        ACCESS_ENABLER_SCRIPT_URL,
      },
    } = getConfig();

    if (typeof window[ACCESS_ENABLER_OBJ] === 'undefined') {
      // Load AccessEnabler script
      loadScript(ACCESS_ENABLER_SCRIPT_URL || getAEConfig('script'))
        .then(() => {
          // Set up API
          window[ACCESS_ENABLER_OBJ] = new window.Adobe.AccessEnabler(
            getAEConfig('softwareStatement'),
          );

          // AccessEnabler callback methods
          window.createIFrame = this.accessEnablerCreateIFrame;
          window.destroyIFrame = this.accessEnablerDestroyIFrame;
          window.displayProviderDialog = this.accessEnablerDisplayProviderDialog;
          window.entitlementLoaded = this.accessEnablerLoaded;
          window.handleAccessEnablerErrors = this.accessEnablerError;
          window.selectedProvider = this.accessEnablerSelectedProvider;
          window.sendTrackingData = this.accessEnablerSendTrackingData;
          window.setAuthenticationStatus = this.accessEnablerSetAuthStatus;
          window.setConfig = this.accessEnablerSetConfig;
          window.setToken = this.accessEnablerSetToken;
          window.setMetadataStatus = this.accessEnablerSetMetadataStatus;
          window.tokenRequestFailed = this.accessEnablerTokenRequestFailed;
        });

      // Register tracking event
      $t('register', [TRACKING_EVENT_NAME]);
    }
  }

  /**
   * The user selected a MVPD which requires an iFrame
   */
  accessEnablerCreateIFrame = (width, height) => {
    const { iframeRef, showProviderIFrame } = this.props;
    showProviderIFrame();

    const iframeWrap = iframeRef.current;

    // Create the iframe to the specified width and height for the MVPD login page.
    const iframe = iframeWrap.childNodes[0];
    iframe.style.width = `${width}px`;
    iframe.style.height = `${height}px`;

    this.logDebugMsg('createIframe', iframeRef);
  }

  /**
   * Callback triggered when a MVPD requiring an iFrame authenticates
   */
  accessEnablerDestroyIFrame = () => {
    const { hideProviderIFrame, iframeRef } = this.props;
    hideProviderIFrame();

    const iframeWrap = iframeRef.current;
    // Reset frame source
    const iframe = iframeWrap.childNodes[0];
    iframe.src = configTVE.iframeSrc;
  }

  /**
   * Implement this callback to invoke your own custom provider-selection UI
   * @param {array} providers An array of Objects representing the requested MVPDs
   */
  accessEnablerDisplayProviderDialog = () => { }

  /**
   * Callback triggered by initial load of the Access Enabler
   */
  accessEnablerLoaded = () => {
    const { disableBackgroundLogin, domain } = this.props;
    const {
      [domain]: {
        backgroundLogin,
        backgroundLogout,
        requestorID,
      } = {},
    } = configTVE;

    if (requestorID) {
      const enabler = window[ACCESS_ENABLER_OBJ];
      enabler.bind('errorEvent', 'handleAccessEnablerErrors');
      // Set the requestor ID for the authN check
      enabler.setRequestor(requestorID, null, {
        backgroundLogin: (disableBackgroundLogin ? false : backgroundLogin),
        backgroundLogout,
      });
      // Start the authN flow, which triggers setAuthenticationStatus()
      enabler.checkAuthentication();
    }
  }

  /**
   * Handles special errors passed by the Access Enabler (bound by errorEvent)
   * @param {object} error An object with information about the error received
   * @param {string} error.errorId The ID code of the error received
   * @param {string} error.level The severity of the error
   * @param {string} error.message The error message to show to the user
   */
  accessEnablerError = (error) => {
    // http://tve.helpdocsonline.com/error-reporting$adv_err_ref
    if (error.level === 'error') {
      const { errorId } = error;
      if (errorId && Object.keys(notifyErrorMap).includes(errorId)) {
        this.setState({
          error: errorId,
        });
      } else {
        const { setError } = this.props;
        setError(ERROR_TYPES.ERROR);
      }
    }

    this.logDebugMsg('Error', error);
  }

  /**
   * Allow the user to clear the error added above.
   */
  handleClearError = () => {
    this.setState({
      error: null,
    });
  }

  /**
   * Callback triggered by accessEnabler.getSelectedProvider()
   * @param {object} provider The information for the selected provider
   * @param {string} provider.AE_State The Access Enabler user state:
   *   'User Authenticated', 'New User'
   * @param {string} provider.MVPD The MVPD ID of the selected provider
   */
  accessEnablerSelectedProvider = ({ AE_State: aeState, MVPD = false }) => {
    const { setAuthentication } = this.props;

    switch (aeState) {
      case 'User Authenticated':
        // Dispatch global auth/provider update
        setAuthentication({
          authenticated: true,
          provider: MVPD,
        });
        break;
      default:
    }

    this.logDebugMsg('SelectedProvider', { aeState, MVPD });
  }

  /**
   * Callback for tracking events sent from Adobe Pass,
   * triggered by checkAuthentication(), checkAuthorization(), getAuthorization(),
   * and setSelectedProvider()
   * @param {string} eventType The type of event this represents: authenticationDetection,
   *   authorizationDetection, mvpdSelection
   * @param {Array} data A list of tracking data
   */
  accessEnablerSendTrackingData = (eventType, data) => {
    const { setAuthentication } = this.props;

    if (['authenticationDetection', 'authorizationDetection'].includes(eventType)) {
      const [auth] = data;
      if (!auth) {
        setAuthentication({ provider: null });
      }
    }

    this.logDebugMsg('Tracking', { eventType, data });

    // Log tracking event
    $t('track', TRACKING_EVENT_NAME, { action: eventType, data });
  }

  /**
   * Callback triggered by checkAuthentication(), getAuthentication(), and checkAuthorization();
   * @param {number} authed 1 for authenticated or 0 for not authenticated
   * @param {string} error The error to be displayed, if any
   */
  accessEnablerSetAuthStatus = (authed, error) => {
    const {
      domain,
      selectedProvider,
      setAuthentication,
      setAuthorization,
    } = this.props;

    // authenticated can be "1" or 1
    if (parseInt(authed, 10) === 1) {
      // Authenticated
      const {
        [domain]: {
          channelTitle, itemTitle, guid,
        } = {},
      } = configTVE;

      const resourceID = getResourceID(channelTitle, itemTitle, guid);

      const enabler = window[ACCESS_ENABLER_OBJ];
      // Confirm the authenticated provider
      enabler.getSelectedProvider();
      // Begin authZ check, triggers setToken() or tokenRequestFailed()
      enabler.getAuthorization(resourceID);
      // Extract metadata details
      enabler.getMetadata('userID');
      enabler.getMetadata('hba_status');

      // Dispatch global authentication state update
      setAuthentication({ authenticated: true });
    } else {
      // Not Authenticated. Dispatch global authorization state update
      setAuthorization({ token: null });
    }

    this.logDebugMsg('SetAuthStatus', { authed, error, selectedProvider });
  }

  /**
   * Receive the configuration information and MVPD list.
   */
  accessEnablerSetConfig = (config) => {
    if (/^<config>/.test(config)) {
      const Parser = new DOMParser();
      const DOM = Parser.parseFromString(config, 'application/xml');
      // Find tempPass MVPDs
      const MVPDs = DOM.querySelectorAll('config > requestor > mvpds > mvpd > tempPass');
      for (let m = 0; m < MVPDs.length; m += 1) {
        const id = MVPDs[m].parentNode.querySelector('id');
        // Ignore flexibleTempPass
        if (id?.textContent && id.textContent.indexOf('flex') === -1) {
          const { setTempPassProvider } = this.props;
          setTempPassProvider(id.textContent);
          break;
        }
      }
    }
  }

  /**
   * Callback triggered by checkAuthorization() or getAuthorization() on authZ success
   * @param {string} resourceID The resource ID for which authorization was obtained
   * @param {string} token The short media token
   */
  accessEnablerSetToken = (resourceID, token) => {
    const { selectedProvider, setAuthorization } = this.props;

    // Dispatch global authorization update
    setAuthorization({ token });

    this.logDebugMsg('SetToken', { resourceID, token, selectedProvider });
  }

  /**
   * Callback triggered by the Access Enabler that delivers the metadata requested
   * via a getMetadata() call.
   * @param {string} key The key of the metadata for which the request was made
   * @param {bool} encrypted A flag signifying whether the "value" is encrypted or not
   * @param {object} data
   */
  accessEnablerSetMetadataStatus = (...args) => {
    this.logDebugMsg('SetMetaData', args);

    const key = args[0];
    if (!key) {
      return;
    }
    const { setAccountMetadata } = this.props;
    setAccountMetadata({ [key]: args[2] ?? '' });
  }

  /**
   * Callback triggered by checkAuthorization() or getAuthorization() on authZ failure
   * @param {string} resourceID The resource ID for which authorization was requested
   * @param {string} errorCode The Adobe error code type
   * @param {string} errorMessage The full error message for user display
   */
  accessEnablerTokenRequestFailed = (resourceID, errorCode, errorMessage) => {
    const { authenticated } = this.props;

    switch (errorCode) {
      case 'User Not Authenticated Error':
      case 'User Not Authorized Error':
        if (authenticated) {
          window[ACCESS_ENABLER_OBJ].logout();
        }
        break;

      default:
    }

    this.logDebugMsg('TokenRequestFailed', { resourceID, errorCode, errorMessage });
  }

  logDebugMsg = (/* ...args */) => {
    // eslint-disable-next-line no-console
    // if (window.console) console.log(...args);
  }

  render() {
    const { error } = this.state;

    return (
      <>
        {error && (
          <Notice
            error={error}
            onClick={this.handleClearError}
          />
        )}
      </>
    );
  }
}

export default connect(mapStateToProps, mapActionsToProps)(TVEAuthProvider);
